let availableJobsModal = () => {
    var modal = '.available-job-modal';
    var modalOpenToggle = $('.available-jobs').find('.job');
    var modalCloseToggle = '.available-job-modal-inner-header';

    $(modalOpenToggle).on('click', function() {
        var jobTitle = $(this).data('job-title');
        var jobDescription = $(this).data('job-description');
        var jobRequirements = $(this).data('job-requirements');
        var linkToApply = $(this).data('link-to-apply');
        var modalJobTitle = '.available-job-modal-inner-header';
        var modalJobDescription = '.modal-job-description';
        var modalJobRequirements = '.modal-job-requirements-apply__requirements';
        var modalLinkToApply = '.modal-job-requirements-apply__apply';

        $(modalJobTitle).text(jobTitle);
        $(modalJobDescription).html(jobDescription);
        $(modalJobRequirements).html(jobRequirements);
        $(modalLinkToApply).attr('src', linkToApply);
        $(modal).fadeIn(200);
    });

    $(modalCloseToggle).on('click', function() {
        $(modal).fadeOut(200);
    });

};

export default availableJobsModal;