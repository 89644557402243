let flexibleMediaItemImageGallery = () => {
    var imageGallery = '.image-gallery';
    var imageGalleryImage = '.image-gallery-image';


    $(imageGallery).each(function (index) {
        var currentImage = 0;
        var numberOfImages = $(this).find(imageGalleryImage).length;

        let imageSwapperStyles = (valueSetter, event) => {

            // add an event param so we can either jump to an index, or increment/decrement
            if (event == 'click') {
                currentImage = valueSetter;
            }

            // set the active state if the currentImage value matches the index
            $('.carousel-indexer__index').eq(currentImage).addClass('active')

            // set the inactive state if the currentImage value does not match the index
            $('.carousel-indexer__index').not(':eq(' + currentImage + ')').removeClass('active')

            // show the image
            $(imageGalleryImage).eq(currentImage).fadeIn();
            
            // hide the image
            $(imageGalleryImage).not(':eq(' + currentImage + ')').fadeOut();
        };

        // if only 2 images, do a column style
        if (numberOfImages <= 2) {
            $(imageGallery).addClass('two-column');
            // if more than 2 images, do a carousel style
        } else if (numberOfImages > 2) {
            $(imageGallery).addClass('carousel');
            $(imageGallery).append('<div class="carousel-indexer"></div>')

            $(this).find(imageGalleryImage).each(function (index) {
                // add the indexer at the bottom, and set the first index to active
                if (index == 0) {
                    $('.carousel-indexer').append('<div class="carousel-indexer__index active"></div>')
                } else {
                    $('.carousel-indexer').append('<div class="carousel-indexer__index"></div>')
                }
                // set the index widths
                $('.carousel-indexer__index').css({
                    'width': ((100 - 20) / numberOfImages) + '%',
                });
            });

            // navigate to image based on indexer clicks
            $('.carousel-indexer__index').each(function (index) {
                $(this).on('click', function () {
                    imageSwapperStyles(index, 'click');
                })
            });

            // cycle images based on control clicks
            $('.js-image-gallery-control').each(function () {
                $(this).on('click', function () {
                    if ($(this).hasClass('left') && currentImage != 0) {
                        imageSwapperStyles(currentImage--);
                    } else if ($(this).hasClass('right') && currentImage != numberOfImages - 1) {
                        imageSwapperStyles(currentImage++);
                    }
                })
            });
        }
    })
};

export {
    flexibleMediaItemImageGallery
};