let serviceMenu = () => {
    var controller = new ScrollWizardry.Controller();
    var serviceMenu = '.service-menu';
    var serviceMenuItem = '.js-service-menu-item';
    var flexibleServiceContent = '.flexible-service-content';

    $(serviceMenuItem).each(function (index) {
        // get the section title
        var serviceSectionTitle = $(this).text();

        // construct the IDs for the section anchors
        var serviceMenuID = 'service-menu-item-' + index;

        // pass the anchor target IDs into each section
        $(this).attr('id', serviceMenuID);

        // pass the titles and andchor ID links into the service menu
        $(serviceMenu).append('<div class="service-menu__item"><a href="#' + serviceMenuID + '">' + serviceSectionTitle + '</a></div>')

        var serviceMenuItemScene = new ScrollWizardry.Scene({
            triggerElement: this,
            triggerHook: .5,
            offset: 0,
            duration: $(this).parent().outerHeight(true),
        }).addTo(controller);

        serviceMenuItemScene.on('progress', function(event) {
            // set the active and inactive states on scroll
            $('.service-menu__item').eq(index).addClass('active');
            $('.service-menu__item').not(':eq(' + index + ')').removeClass('active');
        });
    })

    var serviceMenuScene = new ScrollWizardry.Scene({
        triggerElement: flexibleServiceContent,
        triggerHook: .5,
        offset: 0,
        // end the scene at the start of the last section
        duration: $(serviceMenuItem).last().offset().top - $(flexibleServiceContent).offset().top,
    }).setPin(serviceMenu, {
        pushFollowers: false
    }).addIndicators().addTo(controller);
};

export default serviceMenu;