import clientAutoScroller from './components/clientAutoScroller.js';
import {
    flexibleMediaItemImageGallery
} from './components/flexibleMediaItem.js';
import serviceMenu from './components/serviceMenu.js';
import availableJobsModal from './components/availableJobsModal.js';
import rolly from 'rolly.js';

// helper for checking page
// function pageChecker(pageClass) {
//     return $('#content').hasClass(pageClass)
// }

$(document).ready(function () {
    // const view = document.querySelector('.app');
    // const r = rolly({
    //     view,
    //     native: true,
    //     // other options
    // });
    // r.init();

    // if (pageChecker('front-page')) {
    //     clientAutoScroller()
    // }

    if ($('.flexible-media-item').hasClass('js-image-gallery')) {
        flexibleMediaItemImageGallery();
    }

    // if (pageChecker('service')) {
    //     serviceMenu();
    // }
    //
    // if (pageChecker('careers')) {
    //     availableJobsModal();
    // }
});


// make img tag svgs targetable by css
$("img.svg").each(function () {
    var $img = jQuery(this);
    var attributes = $img.prop("attributes");
    var imgURL = $img.attr("src");

    $.get(imgURL, function (data) {
        var $svg = $(data).find('svg');
        $svg = $svg.removeAttr('xmlns:a');
        $.each(attributes, function () {
            $svg.attr(this.name, this.value);
        });
        $img.replaceWith($svg);
    });
});

if (!$('a').hasClass('external')) {
    // Add smooth scrolling to all links
    var offset;
    $('a').on('click', function (event) {
        if ($(window))
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();

                // Store hash
                var hash = this.hash;

                // Using jQuery's animate() method to add smooth page scroll
                // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                $('html, body').animate({
                    scrollTop: $(hash).offset().top + 10,
                    easing: "easeInOutQuart",
                }, 500, function () {

                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.location.hash = hash;
                });
            } // End if
    });
}