let clientAutoScroller = () => {
    var controller = new ScrollMagic.Controller();
    var clientScrollerStatus = false;
    var clientScrollerComplete = false;

    // client scroller
    var clientScroller = '.client-scroller';
    var intervalCounter = 0;
    var scrollCounter = 0;
    var clientScrollerScene = new ScrollMagic.Scene({
        triggerElement: '#client-scroller-scene',
        triggerHook: 1,
        offset: 0,
        duration: $('#client-scroller-scene').outerHeight(true),
    }).addTo(controller);

    let clientTranslator = () => {
        $(clientScroller).css({
            'transition': '.1s all ease-out',
            'transform': 'translateX(' + (-((scrollCounter * 4) + (intervalCounter * 4))) + 'px)',
        })
    };

    let completeClientScroller = () => {
        $('.client-scroller-image').each(function (index) {
            var offset = $(this).offset().left;
            if (index == $('.client-scroller-image').length - 1) {
                if (offset <= ($(window).width() / 2) - ($(this).width() / 2)) {
                    clientScrollerComplete = true;
                }
            }
        });
    };

    clientScrollerScene.on('start', function (event) {
        if (event.scrollDirection == "FORWARD") {
            clientScrollerStatus = true;
        } else if (event.scrollDirection == "REVERSE") {
            clientScrollerStatus = false;
        }
    });


    clientScrollerScene.on('update', function (event) {
        if (clientScrollerStatus && !clientScrollerComplete) {
            scrollCounter++
            clientTranslator();
        }

        completeClientScroller();
    });

    setInterval(function () {
        if (clientScrollerStatus && !clientScrollerComplete) {
            intervalCounter++
            clientTranslator();
        }

        completeClientScroller();
    }, 100)
};

export default clientAutoScroller;